'use client';
import { refreshTags } from '@edeeone/juan-core/refreshTags';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useProductComparisonGetItemsQuery } from '@edeeone/shop-productcomparison/graphql/productComparison.codegen';
import { productComparisonAddItem, productComparisonAddItems, productComparisonRemoveAllItems, productComparisonRemoveItem, } from '@edeeone/shop-productcomparison/graphql/productComparisonResolver';
import { useRouter } from 'next/navigation';
/**
 * Hook managing the product comparison from user's profile.
 * @returns An object conforming to the {ProductComparisonHook} interface, providing functionalities to interact with the product comparison.
 * @throws Will throw an error if used outside of a `UserProfileProvider`.
 */
export const useProductComparison = () => {
    const { refresh: refreshPage } = useRouter();
    const { routeId } = useLocation();
    const isProductComparisonPage = [
        '/shop-productComparison/ProductComparison',
        '/shop-productComparison/shared-productComparison',
    ].includes(routeId);
    /**
     * Refreshes the page if the user is on a product page and always refreshes the user profile context.
     */
    const refreshProductComparison = async () => {
        if (isProductComparisonPage) {
            refreshPage();
        }
        return refreshTags(['productComparison']);
    };
    const { data } = useProductComparisonGetItemsQuery({
        context: {
            tags: ['productComparison', 'userProfile'],
        },
    });
    const productComparisonItems = (data?.productComparisonGetItems?.recordStrip
        ?.data || []);
    const productComparisonCount = productComparisonItems.length;
    const isProductComparisonEmpty = productComparisonCount === 0;
    /**
     * Checks if a product is present in the product comparison.
     */
    const isProductInProductComparison = (productId) => {
        return productComparisonItems.some((productComparisonItem) => {
            return productComparisonItem?.primaryKey === productId;
        });
    };
    /**
     * Adds a product to the product comparison.
     *
     * @returns It returns the `listCode` of the product comparison to which the product was added
     */
    const addProductToProductComparison = async (productId) => {
        const { listCode } = await productComparisonAddItem(productId);
        await refreshProductComparison();
        return listCode;
    };
    /**
     * Adds a product to the product comparison.
     *
     * @returns It returns the `listCode` of the product comparison to which the product was added
     */
    const addProductsToProductComparison = async (productIds) => {
        const { listCode } = await productComparisonAddItems(productIds);
        await refreshProductComparison();
        return listCode;
    };
    /**
     * Removes a product from the product comparison based on product id independently it is a variant of product or master product.
     */
    const removeProductFromProductComparison = async (productId) => {
        await productComparisonRemoveItem(productId);
        await refreshProductComparison();
    };
    /**
     * Removes all products from the product comparison
     */
    const removeAllProductsFromProductComparison = async () => {
        await productComparisonRemoveAllItems();
        await refreshProductComparison();
    };
    return {
        productComparisonItems,
        productComparisonCount,
        isProductComparisonEmpty,
        isProductInProductComparison,
        addProductToProductComparison,
        addProductsToProductComparison,
        removeProductFromProductComparison,
        removeAllProductsFromProductComparison,
    };
};
