import { getMemoizedFinalClassnames } from '../../../hooks/useFinalClassnames';
export const selectBoxDefaultClassNames = /* tw */ {
    container: 'selectbox w-full relative',
    input: 'selectbox__btn border-form radius-form p-form-select text-form bg-white w-full text-left flex justify-between items-center',
    input__open: '!border-gray-60',
    input__error: '!border-error !text-error',
    input_ico: 'selectbox__btn-ico transition-common w-[.9rem] h-[.9rem]',
    input_ico__open: 'rotate-180',
    itemsContainer: 'selectbox__dropdown absolute w-full bg-white top-[5.5rem] border border-gray-20 shadow-horizontal-filter z-30 py-6 rounded-3xl',
    item: 'selectbox__item flex justify-between px-12 py-5 text-sm text-gray-70 font-normal cursor-pointer hover:bg-gray-10 transition-common ',
    item__selected: 'font-medium text-black',
    item__disabled: 'cursor-auto',
    itemLabelPrefix: 'selectbox__item-prefix',
    itemLabel: 'selectbox__item-label text-sm flex justify-between items-center',
    itemLabelDisabled: 'text-gray-30',
    itemLabelSuffix: 'selectbox__item-suffix text-xs text-gray-60 font-normal flex justify-between items-center gap-1',
    itemIcon: 'selectbox__item-ico ico ml-3 h-4 w-4 fill-black',
    primary: {},
};
export function selectBoxClassNames(...args) {
    return getMemoizedFinalClassnames('selectBoxClassNames', selectBoxDefaultClassNames, ...args);
}
