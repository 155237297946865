import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const FieldsPriceFragmentDoc = gql `
    fragment fieldsPrice on PriceForSale {
  priceWithTax
  priceWithoutTax
  currency
  validity
}
    `;
export const ProductPricesFieldsFragmentDoc = gql `
    fragment productPricesFields on Product {
  multiplePricesForSaleAvailable(currency: $currency)
  priceForSale(validNow: true, currency: $currency) {
    ...fieldsPrice
  }
  allPricesForSale(priceLists: $priceLists, currency: $currency) {
    accompanyingPrice(priceLists: $priceLists) {
      priceWithTax
      priceWithoutTax
      currency
      validity
    }
    ...fieldsPrice
  }
  attributes(locale: $locale) {
    saleEnded
  }
}
    ${FieldsPriceFragmentDoc}`;
export const AvailabilityFieldsFragmentDoc = gql `
    fragment availabilityFields on Product {
  stocks {
    attributes {
      quantityOnStock
    }
    referencedEntity {
      attributes(locale: $locale) {
        name
        code
      }
    }
  }
  attributes(locale: $locale) {
    availability
  }
}
    `;
export const ImageFieldsFragmentDoc = gql `
    fragment imageFields on RelatedFiles {
  fileIds
  files {
    src
    alt
  }
}
    `;
export const RecordStripFieldsFragmentDoc = gql `
    fragment recordStripFields on ProductRecordStrip {
  first
  empty
  limit
  totalRecordCount
  offset
  data {
    primaryKey
    associatedData {
      localization
    }
    categories {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
        }
      }
    }
    relatedProducts {
      attributes {
        category
      }
    }
    variants {
      referencedEntity {
        attributes {
          codeShort
        }
      }
    }
    ...productPricesFields
    ...availabilityFields
    attributes(locale: $locale) {
      name
      descriptionShort
      codeShort
      url
      productType
      ratingVotes
      rating
      motive: relatedFiles(category: "hlavni-motiv") {
        ...imageFields
      }
      minOrderQuantity
      saleEnded
    }
    master {
      referencedPrimaryKey
    }
    tags {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
          isVisibleInDetail
          isVisibleInFilter
          isVisibleInListing
        }
        type
      }
    }
    parameterValues(
      filterBy: {inScope: {scope: LIVE, filtering: {attributeVariantEquals: true}}}
    ) {
      referencedEntity {
        primaryKey
        attributes {
          name
          code
          order
        }
        parameter {
          attributes {
            orderInParameter
          }
          referencedEntity {
            attributes {
              name
              code
              isVisibleInDetail
            }
          }
        }
      }
    }
  }
}
    ${ProductPricesFieldsFragmentDoc}
${AvailabilityFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const WishListAddItemDocument = gql `
    mutation wishListAddItem($input: WishListItemInput) {
  wishListAddItem(input: $input) {
    error {
      message
      messageCode
    }
    listCode
    status
  }
}
    `;
/**
 * __useWishListAddItemMutation__
 *
 * To run a mutation, you first call `useWishListAddItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishListAddItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishListAddItemMutation, { data, loading, error }] = useWishListAddItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWishListAddItemMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(WishListAddItemDocument, options);
}
export const WishListRemoveItemDocument = gql `
    mutation wishListRemoveItem($input: WishListItemInput) {
  wishListRemoveItem(input: $input) {
    error {
      message
      messageCode
    }
    status
  }
}
    `;
/**
 * __useWishListRemoveItemMutation__
 *
 * To run a mutation, you first call `useWishListRemoveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishListRemoveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishListRemoveItemMutation, { data, loading, error }] = useWishListRemoveItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWishListRemoveItemMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(WishListRemoveItemDocument, options);
}
export const WishListRemoveAllItemsDocument = gql `
    mutation wishListRemoveAllItems {
  wishListRemoveAllItems {
    error {
      message
      messageCode
    }
    status
  }
}
    `;
/**
 * __useWishListRemoveAllItemsMutation__
 *
 * To run a mutation, you first call `useWishListRemoveAllItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishListRemoveAllItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishListRemoveAllItemsMutation, { data, loading, error }] = useWishListRemoveAllItemsMutation({
 *   variables: {
 *   },
 * });
 */
export function useWishListRemoveAllItemsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(WishListRemoveAllItemsDocument, options);
}
export const WishListShareEmailDocument = gql `
    mutation wishListShareEmail($args: WishListArgs, $input: WishListShareEmailInput) {
  wishListShareEmail(args: $args, input: $input)
}
    `;
/**
 * __useWishListShareEmailMutation__
 *
 * To run a mutation, you first call `useWishListShareEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishListShareEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishListShareEmailMutation, { data, loading, error }] = useWishListShareEmailMutation({
 *   variables: {
 *      args: // value for 'args'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWishListShareEmailMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(WishListShareEmailDocument, options);
}
export const GetWishItemDocument = gql `
    query getWishItem($locale: CatalogLocale, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"]) {
  wishListGetItems {
    recordStrip(offset: 0, limit: 150) {
      ...recordStripFields
    }
  }
}
    ${RecordStripFieldsFragmentDoc}`;
/**
 * __useGetWishItemQuery__
 *
 * To run a query within a React component, call `useGetWishItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWishItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWishItemQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *   },
 * });
 */
export function useGetWishItemQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(GetWishItemDocument, options);
}
export function useGetWishItemLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(GetWishItemDocument, options);
}
export function useGetWishItemSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(GetWishItemDocument, options);
}
export const WishListGetShareDocument = gql `
    query wishListGetShare {
  wishListGetShare
}
    `;
/**
 * __useWishListGetShareQuery__
 *
 * To run a query within a React component, call `useWishListGetShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishListGetShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishListGetShareQuery({
 *   variables: {
 *   },
 * });
 */
export function useWishListGetShareQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(WishListGetShareDocument, options);
}
export function useWishListGetShareLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(WishListGetShareDocument, options);
}
export function useWishListGetShareSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(WishListGetShareDocument, options);
}
export const WishListGetShareDataDocument = gql `
    query wishListGetShareData($args: WishListArgs, $locale: CatalogLocale, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"]) {
  wishListGetShareData(args: $args) {
    recordStrip(offset: 0, limit: 200) {
      ...recordStripFields
    }
  }
}
    ${RecordStripFieldsFragmentDoc}`;
/**
 * __useWishListGetShareDataQuery__
 *
 * To run a query within a React component, call `useWishListGetShareDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishListGetShareDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishListGetShareDataQuery({
 *   variables: {
 *      args: // value for 'args'
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *   },
 * });
 */
export function useWishListGetShareDataQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(WishListGetShareDataDocument, options);
}
export function useWishListGetShareDataLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(WishListGetShareDataDocument, options);
}
export function useWishListGetShareDataSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(WishListGetShareDataDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_GetWishItemQuery = async function (options) {
    return getGqlData({
        ...options,
        query: GetWishItemDocument,
    });
};
export const getData_WishListGetShareQuery = async function (options) {
    return getGqlData({
        ...options,
        query: WishListGetShareDocument,
    });
};
export const getData_WishListGetShareDataQuery = async function (options) {
    return getGqlData({
        ...options,
        query: WishListGetShareDataDocument,
    });
};
