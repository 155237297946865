import { getMemoizedFinalClassnames } from '../../hooks/useFinalClassnames';
export const tabsDefaultClassNames = /* tw */ {
    container: 'tabs flex justify-center relative',
    container__border: 'border-b border-b-gray-20',
    container__fadingBox: 'fading-box',
    container__overflow: 'min-w-full max-w-[100vw] w-[calc(100vw_-_15px_-_var(--scrollbar-width))] sm:w-[calc(100vw_-_((100vw_-_510px)_/_2)_-_var(--scrollbar-width))] md:w-[calc(100vw_-_((100vw_-_690px)_/_2)_-_var(--scrollbar-width))] lg:w-[calc(100vw_-_((100vw_-_930px)_/_2)_-_var(--scrollbar-width))] xl:w-[calc(100vw_-_((100vw_-_1110px)_/_2)_-_var(--scrollbar-width))] 2xl:w-[calc(100vw_-_((100vw_-_1410px)_/_2)_-_var(--scrollbar-width))]',
    container__custom: '',
    ul__full: 'w-full',
    ul: 'tabs__list flex flex-row flex-nowrap w-auto gap-8 md:gap-12',
    ul__center: 'justify-center',
    ul__left: 'justify-start',
    ul__right: 'justify-end',
    ul__overflow: 'overflow-x-auto overflow-y-hidden whitespace-nowrap no-scrollbar',
    ul__custom: '',
    li: 'tabs__item',
    li__full: 'grow',
    li__current: '',
    li__noWrap: 'whitespace-nowrap',
    dropdown: '',
    dropdown_opener: 'flex gap-4 items-center font-medium text-xs',
    dropdown_opener_info: 'text-gray-60 font-normal',
    dropdown_ul: 'tabs_dropdown_ul flex flex-col gap-8',
    dropdown_li: 'tabs_dropdown_li text-gray-70 text-sm',
    dropdown_a: 'tabs_dropdown_a text-gray-70 hover:text-black transition-common flex gap-4 items-center',
    dropdown_a_active: 'text-black',
    dropdown_a_icon: 'tabs_dropdown_a_icon',
    dropdown_radio: 'tabs_dropdown_radio relative h-[2.2rem] w-[2.2rem] shrink-0 items-center gap-4 hidden',
    dropdown_radioInput: 'tabs_dropdown_radio-input absolute outline-none appearance-none h-[2.2rem] w-[2.2rem] border-radio radius-radio bg-white cursor-pointer peer transition-common group-hover/item:border-gray-60 group-active/item:bg-black',
    dropdown_radioInput__checked: 'bg-black',
    dropdown_radioIcon: 'tabs_dropdown_radio-icon absolute hidden pointer-events-none fill-white w-4 h-4 mx-[.6rem] group-active/item:block',
    dropdown_radioIcon__checked: 'block',
    dropdown_label: 'tabs_dropdown_label',
    // výchozí velikost písma v záložkách
    primary: {
        ul: 'text-tag md:text-s',
    },
    // záložky v modálním okně (přihlášení/registrace)
    secondary: {
        ul: 'text-tag',
    },
    // záložky v modálním okně (výběr odběrného místa)
    tertiary: {
        ul: 'text-s',
    },
    // zobrazí se jako dropdown/modal na malých zařízeních a jako záložky na větších
    showDropdownOnSmallDevice: {
        dropdown: 'tabs__dropdown md:hidden',
        ul: 'hidden md:flex',
        dropdown_radio: 'flex',
    },
    // zobrazí se jako dropdown/modal napříč všemi zařízeními
    showDropdown: {
        dropdown: 'tabs__dropdown',
        ul: 'hidden',
        dropdown_radio: 'flex md:hidden',
    },
    isFullWidth: {},
    isTabFullWidth: {},
    noWrap: {},
    showBorder: {},
};
export function tabsClassNames(...args) {
    return getMemoizedFinalClassnames('tabsClassNames', tabsDefaultClassNames, ...args);
}
