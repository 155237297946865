'use client';
import { refreshTags } from '@edeeone/juan-core/refreshTags';
import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useGetWishItemQuery } from '@edeeone/shop-wishlist/graphql/wishList.codegen';
import { wishListAddItem, wishListRemoveAllItems, wishListRemoveItem, } from '@edeeone/shop-wishlist/graphql/wishListResolver';
import { useRouter } from 'next/navigation';
/**
 * Hook managing the wishlist.
 * @returns An object conforming to the {WishlistHook} interface, providing functionalities to interact with the wishlist.
 */
export const useWishlist = () => {
    const { routeId, locale, currency } = useLocation();
    const { data } = useGetWishItemQuery({
        variables: {
            locale,
            currency,
        },
        context: {
            tags: ['wishlist', 'userProfile'],
        },
    });
    const { refresh: refreshPage } = useRouter();
    const isWishlistPage = [
        '/shop-wishlist/Wishlist',
        '/shop-wishlist/shared-wishlist',
    ].includes(routeId);
    /**
     * Refreshes the page if the user is on a wishlist page and always refreshes the user profile context.
     */
    const refreshWishlist = async () => {
        if (isWishlistPage) {
            refreshPage();
        }
        await refreshTags(['wishlist']);
    };
    /**
     * Checks whether a given wishlist item matches the specified product or master product.
     *
     * This utility function determines if a wishlist item corresponds to the provided product ID
     * or master product ID, regardless of whether the item is a specific product variant or its master product.
     * It ensures that any product or its related master product in the wishlist can be accurately identified.
     */
    const isWishlistProductMatch = (wishlistItem, productId, masterProductId) => {
        const { primaryKey, master } = wishlistItem || {};
        const referencedPrimaryKey = master?.referencedPrimaryKey;
        const isMasterMatch = referencedPrimaryKey
            ? referencedPrimaryKey === productId ||
                referencedPrimaryKey === masterProductId
            : false;
        const isPrimaryKeyMatch = primaryKey === productId || primaryKey === masterProductId;
        return isMasterMatch || isPrimaryKeyMatch;
    };
    const wishlistItems = (data?.wishListGetItems?.recordStrip?.data ??
        []);
    const wishlistCount = wishlistItems.length;
    const isWishlistEmpty = wishlistCount === 0;
    /**
     * Retrieves product from the wishlist base independently is product variant or master.
     *
     * @returns The product from the wishlist if found, otherwise `undefined`.
     */
    const getWishlistProduct = (productId, masterProductId) => {
        return wishlistItems.find((wishlistItem) => {
            return isWishlistProductMatch(wishlistItem, productId, masterProductId);
        });
    };
    /**
     * Checks if a product variant or master is present in the wishlist.
     *
     * @returns `true` if the variant product or its master product is in the wishlist, otherwise `false`.
     */
    const isProductOrMasterProductInWishlist = (productId, masterProductId) => {
        return wishlistItems.some((wishlistItem) => {
            return isWishlistProductMatch(wishlistItem, productId, masterProductId);
        });
    };
    /**
     * Checks if a product is present in the wishlist.
     */
    const isProductInWishlist = (productId) => {
        return wishlistItems.some((wishlistItem) => {
            return wishlistItem?.primaryKey === productId;
        });
    };
    /**
     * Adds a product to the wishlist.
     *
     * @returns It returns the `listCode` of the wishlist to which the product was added
     */
    const addProductToWishlist = async (productId) => {
        const { listCode } = await wishListAddItem(productId);
        await refreshWishlist();
        return listCode;
    };
    /**
     * Removes a product from the wishlist based on product id independently it is a variant of product or master product.
     */
    const removeProductFromWishlist = async (productId, masterProductId) => {
        const wishListItem = getWishlistProduct(productId, masterProductId);
        await wishListRemoveItem(wishListItem.primaryKey);
        await refreshWishlist();
    };
    /**
     * Removes all product from the wishlist
     */
    const removeAllProductsFromWishlist = async () => {
        await wishListRemoveAllItems();
        await refreshWishlist();
    };
    return {
        wishlistItems,
        wishlistCount,
        isWishlistEmpty,
        getWishlistProduct,
        isProductInWishlist,
        isProductOrMasterProductInWishlist,
        addProductToWishlist,
        removeProductFromWishlist,
        removeAllProductsFromWishlist,
    };
};
