import { removeTags } from '@edeeone/string/removeTags';
export const enumerateAlt = (alt, entityName) => {
    if (alt) {
        return alt;
    }
    if (Array.isArray(entityName)) {
        const resultEntityName = entityName
            .map((item) => {
            return removeTags(item);
        })
            .find((item) => {
            return item?.trim() !== '';
        });
        return resultEntityName || '';
    }
    return removeTags(entityName) || '';
};
