import { useLocation } from '@edeeone/juan-core/routes/useLocation';
import { useCartGetCartQuery } from '../graphql/cart.codegen';
export const useUserProfileCart = () => {
    const { region } = useLocation();
    const { data } = useCartGetCartQuery({
        variables: {
            args: {
                catalogRegion: region,
            },
        },
        context: {
            tags: ['orders', 'userProfile'],
        },
        // skip: !hasSession,
    });
    return data?.cartGetCart;
};
